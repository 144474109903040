<template>
    <van-nav-bar title="預覽:)" right-text="關閉" @click-right="$router.back()" />
    <div class="flex-section">
        <div class="table-responsive">
            <div class="chatbox">
                <div id="flex" ref="flexRef"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onActivated, onDeactivated } from 'vue'

import { useRoute } from 'vue-router'

import { genCard1 } from '@/utils/card2'

const route = useRoute()
const flexRef = ref(null)

onActivated(() => {
    flexRef.value.innerHTML = ''
    let flexContent = genCard1(JSON.parse(sessionStorage.getItem('preview')))

    flex2html("flex", flexContent)
})

onDeactivated(() => {
    sessionStorage.removeItem('preview')
})

</script>

<style lang="less" scoped>
.flex-section {
    // color: white;
    // z-index: 99999;
    background-color: #666;
}


.chatbox {
    background-color: #666;
    margin-top: 10px;
    padding-top: 10px;
}


.table-responsive {
    width: 100%;
    overflow-x: auto;
}
</style>