<template>
    <van-nav-bar title="教學影片" right-text="關閉" @click-right="$router.push('/card/edit')" />
    <div class="yt-content">
        <iframe width="375" height="215" src="https://www.youtube.com/embed/LTb9ubi5qXo" title="SlashCard教學"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </div>
</template>

<script setup>
</script>

<style lang="less" scoped>
.yt-content {
    text-align: center;
    background-color: #666;
}
</style>