import _ from "lodash";

function vcardUuid(vcard, secret) {
  const hash = CryptoJS.HmacMD5(JSON.stringify(vcard), secret);
  const hex = CryptoJS.enc.Hex.stringify(hash);
  return hex.replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, "$1-$2-$3-$4-$5");
}

function renderBtn(ctx) {
  const { btn, json5: vcard } = ctx;
  const uri = btn.link || DEFAULT_LINK;
  return {
    color: btn.color || "#17c950",
    height: btn.btnHeight || "md",
    style: btn.style || "primary",
    type: "button",
    action: {
      label: btn.text || "預設按鈕文字",
      type: "uri",
      uri,
    },
  };
}

function renderCard(ctx) {
  const { card, cardIdx, json5: vcard } = ctx;

  let rCard = {
    type: "bubble",
    hero: {
      animated: true,
      aspectMode: "cover",
      aspectRatio: card.ratio || "20:13",
      size: "full",
      type: "image",
      url: card.image || " ",
      // action: {
      //   type: 'uri',
      //   uri,
      // },
    },
    body: {
      backgroundColor: card.bgColor || "#ffffff",
      layout: "vertical",
      spacing: "md",
      type: "box",
      // action: {
      //   type: 'uri',
      //   uri,
      // },
      contents: [
        {
          color: card.titleColor || "#000000",
          size: card.titleSize || "xl",
          text: card.title || " ",
          type: "text",
          weight: "bold",
          wrap: true,
        },
        {
          color: card.descColor || "#000000",
          size: card.descSize || "sm",
          text: card.desc || " ",
          type: "text",
          wrap: true,
        },
      ],
    },
    footer: {
      backgroundColor: card.bgColor || "#ffffff",
      layout: "vertical",
      spacing: "sm",
      type: "box",
      contents: _.map(card.btns, (btn) => renderBtn({ ...ctx, btn })),
    },
  };

  const uri = card.link || ''

  if(uri.length > 0){
    rCard.hero.action = {
        type: 'uri',
        uri,
      }
  }

  return rCard
}

function genCard1(ctx) {
  const { json5: vcard } = ctx;

  vcard.cards = vcard.cards.filter(item => item.isShow == true)

  return {
    type: "flex",
    altText: vcard.altText,
    contents: {
      type: "carousel",
      contents: _.map(vcard.cards, (card, cardIdx) =>
        renderCard({ ...ctx, card, cardIdx })
      ),
    },
  };
}

export { genCard1 };
