<template>
  <div>
    <van-nav-bar 
      title="商務卡片" 
      right-text="關閉"
      @click-right="$router.push('/')"
    />
    <div class="recommend">
    <div style="text-align: center; line-height: 24px; font-weight: bold;">
    <br />
    <span style="font-size: 19px; color: #cc0000;">
    您沒有設定自製名片權限<br />
    請點選下方立即購買按鈕<br />
    開通自製名片相關服務<br />
    </span>
    <br />
    <ul>
    <li><img src="./images/0001.png" width="100%"></li>
    <li><img src="./images/0002.png" width="100%"></li>
    <li><img src="./images/0003.png" width="100%"></li>
    <li><img src="./images/0004.png" width="100%"></li>
    </ul>
    <form name="" class="fbqs" style="text-align: center;">
    <input type="button" value="立即購買" class="submit" style="padding: 3%; width: 50%; margin-left: auto; margin-right: auto;"
      @click="router.push('/shop')"
    >
    </form>
    </div>
    </div>
  <Footer/>
  </div>
</template>

<script setup>

import { useRouter} from 'vue-router'

import Footer from '@/components/Footer'

const router = useRouter()

</script>

<style lang="less" scoped>
.recommend {
	width: 100%;
	padding-top: 2%;
}
.recommend ul {
	width: 80%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 auto 5% auto;
}
.recommend ul li {
	width: 21%;
	display: flex;
	flex-direction: column;
	margin: 1%;
}
.recommend ul li p {
	margin-top: 2%;
	font-size: 15px;
	color: #333;
}
.recommend ul li span {
	font-size: 0.24rem;
	color: #999;
	padding-top: 0.15rem;
}

</style>